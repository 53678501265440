<template>
  <v-container class="pa-4 grey lighten-5" fluid>
    <portal to="appBarContentLeft">
      <span class="headline font-weight-light">{{ org.name }}</span>
    </portal>
    <v-col class="pa-0" cols="12">
      <v-card flat class="flex mb-4" outlined min-height="200">
        <v-card flat color="transparent" class="pa-4 pb-1">
          <v-row align="center" justify="space-between" class="mb-2" no-gutters>
            <span class="font-weight-bold text-h6">Organization User Accounts</span>
            <v-btn color="primary" text depressed @click="openUserAddDialog()">
              <v-icon left>mdi-plus</v-icon>add user
            </v-btn>
          </v-row>
        </v-card>

        <v-divider></v-divider>
        <v-data-table
          :items="users"
          item-key="displayName"
          class="elevation-0"
          :headers="headers"
          :loading="usersLoading"
          :items-per-page="5"
          height="357"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(user, index) in items" :key="index">
                <td>
                  <v-list-item class="px-0">
                    <v-list-item-content>
                      <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td>
                  <v-layout row justify-center align-center>
                    <v-checkbox
                      hide-details
                      class="pa-0 ml-2 mt-0 mb-0"
                      color="primary"
                      @change="userRolesOnChange(user)"
                      v-model="user.roles.admin"
                    ></v-checkbox>
                  </v-layout>
                </td>
                <td style="max-width:120px; width:120px;">
                  <v-layout row class="mx-0" justify-center>
                    <v-checkbox
                      hide-details
                      class="pa-0 ml-2 mt-0 mb-0"
                      color="primary"
                      @change="userRolesOnChange(user)"
                      v-model="user.roles.qhp"
                    ></v-checkbox>
                  </v-layout>
                </td>
                <td style="max-width: 80px; width: 80px;">
                  <v-layout row justify-center>
                    <v-btn color="red" @click="openUserRemoveDialog(user)" icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>

      <v-layout class="patient-page" justify-start>
        <org-user-add-dialog @submit="userAddDialogSubmit" ref="userAddDialog" />
        <org-user-remove-dialog @submit="userRemoveDialogSubmit" ref="userRemoveDialog" />
        <v-flex xs12>
          <v-expand-transition>
            <v-card flat class="flex mb-4" color="grey lighten-4" min-height="200" v-show="invites.length > 0">
              <v-card flat color="transparent" class="pa-4 pb-1">
                <v-row align="center" justify="space-between" class="mb-2" no-gutters>
                  <span class="font-weight-bold text-h6">Pending Invites</span>
                </v-row>
              </v-card>

              <v-divider></v-divider>
              <v-data-table
                class="elevation-0 grey lighten-4"
                style="max-width: 100%"
                :items="invites"
                item-key="id"
                :headers="inviteHeaders"
                :hide-default-header="isMobile"
                hide-default-footer
                :loading="invitesLoading"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(invite, index) in items" :key="index">
                      <td>{{ invite.email }}</td>
                      <td>{{ invite.expires }}</td>
                      <td style="max-width: 80px; width: 80px;">
                        <v-layout row justify-center>
                          <v-btn color="red" @click="deleteInviteWithToast(invite.id)" icon>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-layout>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-expand-transition>
        </v-flex>
      </v-layout>
      <org-settings :org="org.id" class="mb-4" />
      <org-settings-reminder-texts class="mb-4" />
      <alert-rule-settings :orgMode="true" class="mb-4" />
      <org-note-templates class="mb-4" />
      <org-text-templates />
    </v-col>
  </v-container>
</template>
<style>
.headerStyle {
  background-color: green;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import OrgUserAddDialog from '@/components/dialogs/OrgUserAdd'
import OrgUserRemoveDialog from '@/components/dialogs/OrgUserRemove'
import bus from '@/core/helpers/bus'
import OrgNoteTemplates from '../components/OrgNoteTemplates.vue'
import OrgTextTemplates from '../components/OrgTextTemplates.vue'
import OrgSettings from '@/components/OrgSettings'
import OrgSettingsReminderTexts from '../components/OrgSettingsReminderTexts.vue'
import AlertRuleSettings from '@/components/AlertRuleSettings'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      resetLoading: false,
      email: null,
      emailRules: [v => !!v || 'E-mail is required'],
      headers,
      inviteList: [],
      inviteHeaders,
    }
  },
  components: {
    OrgSettings,
    AlertRuleSettings,
    OrgNoteTemplates,
    OrgTextTemplates,
    OrgSettingsReminderTexts,
    OrgUserAddDialog,
    OrgUserRemoveDialog,
  },
  computed: {
    ...mapGetters('auth', ['isOrgAdmin']),
    ...mapState('org', ['org', 'invites', 'invitesLoading', 'users', 'usersLoading']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('org', ['deleteInvite', 'inviteUser', 'removeUser', 'updateUserRoles']),
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    deleteInviteWithToast(inviteId) {
      this.deleteInvite(inviteId)
        .then(() => {
          this.toast('success', 'Invite Removed')
        })
        .catch(error => {
          console.error(error)
          this.toast('error', 'Error Removing Invite')
        })
    },
    openUserAddDialog() {
      this.$refs.userAddDialog.open()
    },
    openUserRemoveDialog(user) {
      this.$refs.userRemoveDialog.open({ user })
    },
    sendReset() {
      if (this.$refs.pwreset.validate()) {
        this.resetPassword(this.email)
          .then(() => {
            bus.$emit('toast', { type: 'success', text: 'Email Sent' })
          })
          .catch(error => {
            console.log(error)
            bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
          })
      }
    },
    userAddDialogSubmit(email, roles) {
      this.inviteUser(email, roles)
        .then(autoAdded => {
          if (autoAdded) {
            this.toast('success', 'User Added to Organization')
          } else {
            this.toast('success', 'Invitation Email Sent')
          }
        })
        .catch(error => {
          console.error(error)
          this.toast('error', 'Error Inviting User: ' + error)
        })
    },
    userRemoveDialogSubmit(userId) {
      this.removeUser(userId)
        .then(() => {
          this.toast('success', 'User Removed')
        })
        .catch(error => {
          console.error(error)
          this.toast('error', 'Error Removing User')
        })
    },
    userRolesOnChange(user) {
      this.updateUserRoles(user)
        .then(() => {
          this.toast('success', 'Permissions Updated')
        })
        .catch(error => {
          console.log(error)
          this.toast('error', 'Please Try Again Later')
        })
    },
  },
  beforeMount() {
    if (!this.isOrgAdmin) {
      this.$router.push({ name: 'Inbox' })
    }
  },
}

const inviteHeaders = [
  {
    text: 'Recipient',
    align: 'start',
    sortable: false,
    value: 'email',
  },
  {
    text: 'Expiration',
    align: 'start',
    sortable: false,
    value: 'expires',
  },
  {
    text: 'Delete',
    align: 'center',
    sortable: false,
  },
]
const headers = [
  {
    text: 'User',
    align: 'start',
    sortable: false,
    value: 'displayName',
  },
  {
    text: 'Admin',
    align: 'center',
    sortable: false,
  },
  {
    text: 'QHP',
    align: 'center',
    sortable: false,
  },
  {
    text: 'Remove',
    align: 'center',
    sortable: false,
  },
]
</script>
