<template>
  <v-card flat class="flex pb-8" outlined min-height="200">
    <v-card flat color="transparent" class="pa-4">
      <v-row align="center" justify="space-between" class="mb-2" no-gutters>
        <span class="font-weight-bold text-h6">
          Settings and Tools
        </span>
        <v-btn v-show="false"></v-btn>
      </v-row>
      <v-row class="" no-gutters>
        <span class="font-weight-light text-body-1">
          The following items affect how the platform is used within the
          organization.
        </span>
      </v-row>
    </v-card>

    <v-divider class=""></v-divider>
    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Organization Display Name
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-3">
      <v-row no-gutters>
        <v-flex xs12 sm8 md6>
          Enter the name of the organization. This name is displayed on the left
          hand navigation bar and in other areas to identify the entire
          organization.
        </v-flex>
        <v-layout row class="mx-2">
          <v-layout justify-end row class="mr-2">
            <v-form ref="orgNameForm">
              <v-text-field
                @blur="nameBlur"
                @change="nameChanged = true"
                :rules="nameRules"
                style="max-width:450px;"
                v-model="org.name"
                dense
                label="Organization Name"
                outlined
              />
            </v-form>
          </v-layout>
        </v-layout>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Automatic Time Counting
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-2">
      <v-row no-gutters justify="space-between" class="mx-0">
        <v-col xs="12" sm="6" md="6">
          If Automatic Time Counting is enabled, qualified users' time will be
          tracked from the moment they open a patient chart to the moment they
          leave. Users will not be required to start or stop their own time.
        </v-col>
        <v-col xs="6" sm="6" md="6">
          <v-row justify="end" align="center" no-gutters>
            <v-switch
              @change="autoTimeSwitch"
              color="primary"
              v-model="autoCounting"
              :label="autoCounting ? 'Enabled' : 'Disabled'"
              inset
              hide-details
            ></v-switch>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider class="mt-3"></v-divider>

    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Chart Inactivity Timeout
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-2">
      <v-row no-gutters justify="space-between" class="mx-0">
        <v-col xs="12" sm="6" md="6">
          If a user is in a chart and inactive, the system will move the user to
          the main dashboard after a period of time. This helps minimize
          accidental QHP time accrual if a chart is unintentionally left open.
          However, if patient interaction is occuring outside of the system, it
          may be helpful to extend the timeout to prevent the chart from
          closing.
        </v-col>
        <v-col xs="6" sm="6" md="6">
          <v-row justify="end" no-gutters>
            <v-radio-group
              @change="inactivityChange()"
              row
              v-model="chartInactivity"
            >
              <v-radio label="5 Minutes" :value="5"></v-radio>
              <v-radio label="15 Minutes" :value="15"></v-radio>
              <v-radio label="30 Minutes" :value="30"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider class="mt-3"></v-divider>
    <v-card-title class="font-weight-bold text-body-1 pb-0">
      Password Reset Tool
    </v-card-title>

    <v-card-text class="font-weight-regular text-body-2 pb-0">
      <v-row no-gutters justify="space-between" class="mx-0">
        <v-flex xs12 sm8 md6>
          Enter the email address of the user needing a password reset.
          Submitting an email address here will send an email with password
          reset instructions to the identified user.
        </v-flex>
        <v-layout row class="mx-2">
          <v-layout justify-end row>
            <v-form ref="resetPasswordForm">
              <v-text-field
                :rules="emailRules"
                style="max-width:250px;"
                hide-details
                v-model="emailToReset"
                dense
                label="Email"
                outlined
              />
            </v-form>
            <v-btn
              class="ml-2 mr-3"
              @click="sendReset()"
              style="margin-top:2px;"
              depressed
              >Send</v-btn
            >
          </v-layout>
        </v-layout>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import consts from '@/consts'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      autoCounting: false,
      selectedStartDate: 1,
      eventRulesArray: [],
      rulesTable: 0,
      emailRules: [v => !!v || 'E-mail is required'],
      nameRules: [v => !!v || 'Name is required'],
      emailToReset: null,
      metricToAdd: null,
      nameChanged: false,
      showLookup: false,
      conditionSearch: null,
      conditionOptions: [],
      menu: false,
      chartInactivity: 5,
    }
  },
  watch: {
    showLookup(val) {
      if (val) {
        // this.$nextTick(() => (this.$refs.search.focus()))
        this.$nextTick(() => this.$refs.search.activateMenu())
      }
    },
    conditionSearch(val) {
      if (val) {
        this.lookupProblem({ terms: val }).then(data => {
          this.conditionOptions = data
        })
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['org', 'settings']),
    metrics() {
      let metricsToReturn = []
      Object.entries(consts.METRICS).forEach(metric => {
        const [name, details] = metric
        metricsToReturn.push({value: name, text: details.longName})
      })
      return metricsToReturn
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('org', ['update', 'updateSettings']),
    ...mapActions('patient', ['lookupProblem']),
    nameBlur() {
      if (this.nameChanged) {
        this.update({ name: this.org.name })
          .then(() => {
            bus.$emit('toast', { type: 'success', text: 'Updated' })
            this.nameChanged = false
          })
          .catch(error => {
            console.log(error)
            bus.$emit('toast', { type: 'error', text: 'Error Updating' })
          })
      }
    },
    sendReset() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.resetPassword(this.emailToReset).then(() => {
          this.$refs.resetPasswordForm.reset()
          this.$refs.resetPasswordForm.resetValidation()
          bus.$emit('toast', { type: 'success', text: 'Email Sent' })
        }).catch(error => {
          console.log(error)
          this.$refs.resetPasswordForm.reset()
          this.$refs.resetPasswordForm.resetValidation()
          bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
        })
      }
    },
    parseRules() {
      let rulesArray = []
      if (this.org && this.org.thresholds) {
        let keys = Object.keys(this.org.thresholds)
        keys.forEach(key => {
          let item = { metric: key }
          if (this.org.thresholds[key].above) {
            item.operator = 'Above'
            if (this.org.thresholds[key].above.value) {
              item.value = this.org.thresholds[key].above.value
            } else if (this.org.thresholds[key].above.systolicValue) {
              item.value =
                this.org.thresholds[key].above.systolicValue +
                '/' +
                this.org.thresholds[key].above.diastolicValue
            }
          } else {
            item.operator = 'Below'
            if (this.org.thresholds[key].below.value) {
              item.value = this.org.thresholds[key].below.value
            } else if (this.org.thresholds[key].below.systolicValue) {
              item.value =
                this.org.thresholds[key].below.systolicValue +
                '/' +
                this.org.thresholds[key].below.diastolicValue
            }
          }
          this.rulesTable = this.rulesTable + 1
          rulesArray.push(item)
          this.rulesArray = rulesArray
        })
        this.rulesArray = rulesArray
      } else {
        this.rulesArray = rulesArray
      }
    },
    parseRulesObjectObject() {
      let rulesArray = []
      let keys = Object.keys(this.org.defaultPatientEventRules)
      keys.forEach(key => {
        let item = { metric: key }
        if (this.org.defaultPatientEventRules[key].above) {
          item.operator = 'Above'
          if (this.org.defaultPatientEventRules[key].value) {
            item.value = this.org.defaultPatientEventRules[key]
          } else if (
            this.org.defaultPatientEventRules[key].systolicValue
          ) {
            item.value =
              this.org.defaultPatientEventRules[key].systolicValue +
              '/' +
              this.org.defaultPatientEventRules[key].diastolicValue
          }
        } else {
          item.operator = 'Below'
          if (this.org.defaultPatientEventRules[key].value) {
            item.value = this.org.defaultPatientEventRules[key]
          } else if (
            this.org.defaultPatientEventRules[key].systolicValue
          ) {
            item.value =
              this.org.defaultPatientEventRules[key].systolicValue +
              '/' +
              this.org.defaultPatientEventRules[key].diastolicValue
          }
        }

        rulesArray.push(item)
      })
      this.eventRulesArray = rulesArray
    },
    autoTimeSwitch() {
      this.updateSettings({ autoCounting: this.autoCounting })
    },
    inactivityChange() {
      this.updateSettings({ chartInactivity: this.chartInactivity })
    },
  },
  mounted() {
    this.parseRules()
    this.autoCounting = this.settings.autoCounting
    this.chartInactivity = this.settings.chartInactivity
  },
}
</script>

<style></style>
